<script>
	import '../app.css';
	import Header from '$lib/components/common/Header.svelte';
	import Footer from '$lib/components/common/Footer.svelte';
	import { ProgressBar } from '@prgm/sveltekit-progress-bar';
	import { page } from '$app/stores';
</script>

<ProgressBar class="text-orange-600" />

{#if $page.url.pathname.includes('/read')}
	<slot />
{:else}
	<main>
		<Header />

		<slot />

		<Footer />
	</main>
{/if}
