<script>
	import SocialIcons from '$lib/components/common/SocialIcons.svelte';
</script>

<!-- Begin:Footer -->
<footer class="mt-12 bg-gray-50" aria-labelledby="footer-heading">
	<h2 id="footer-heading" class="sr-only">Footer</h2>
	<div class="px-4 py-12 mx-auto max-w-7xl sm:px-6 lg:py-16 lg:px-8">
		<div class="xl:grid xl:grid-cols-3 xl:gap-8">
			<div class="space-y-8 xl:col-span-1">
				<img
					class="h-6"
					src="https://www.dublincore.org/images/dcmi_logo_v802.svg"
					alt="Dublin Core"
				/>
				<p class="text-base text-gray-600">
					DCMI is an organization supporting innovation in metadata design and best practices across
					the metadata ecology.
				</p>
				<!-- Begin: Social Media -->
				<div class="flex space-x-6">
					<SocialIcons />
				</div>
				<!-- End: Social Media -->
				<div class="flex">
					<a href="https://www.cloudflare.com/galileo/">
						<img
							class="h-12"
							src="https://www.dublincore.org/images/logos/galileo_logo.png"
							alt="Powered by Project Galileo"
						/>
					</a>
				</div>
			</div>
			<div class="grid grid-cols-2 gap-8 mt-12 xl:col-span-2 xl:mt-0">
				<div class="md:grid md:grid-cols-2 md:gap-8">
					<div>
						<h3 class="text-base font-medium text-gray-900">Specifications</h3>
						<ul role="list" class="mt-4 space-y-4">
							<li>
								<a
									href="https://www.dublincore.org/specifications/dublin-core/dcmi-terms/"
									class="text-base text-gray-500 hover:text-gray-900"
								>
									DCMI Metadata Terms
								</a>
							</li>

							<li />

							<li>
								<a
									href="https://www.dublincore.org/specifications/dublin-core/"
									class="text-base text-gray-500 hover:text-gray-900"
								>
									DCMI Specifications
								</a>
							</li>

							<li>
								<a
									href="https://www.dublincore.org/schemas/"
									class="text-base text-gray-500 hover:text-gray-900"
								>
									Dublin Core Schemas
								</a>
							</li>

							<li />

							<li>
								<a
									href="https://www.dublincore.org/specifications/lrmi/"
									class="text-base text-gray-500 hover:text-gray-900"
								>
									LRMI
								</a>
							</li>

							<li />

							<li>
								<a
									href="https://www.dublincore.org/specifications/bibo/"
									class="text-base text-gray-500 hover:text-gray-900"
								>
									BIBO
								</a>
							</li>

							<li />
						</ul>
					</div>
					<div class="mt-12 md:mt-0">
						<h3 class="text-base font-medium text-gray-900">Outreach</h3>
						<ul role="list" class="mt-4 space-y-4">
							<li>
								<a
									href="https://www.dublincore.org/conferences/"
									class="text-base text-gray-500 hover:text-gray-900"
								>
									Conferences
								</a>
							</li>

							<li>
								<a
									href="https://www.dublincore.org/webinars/"
									class="text-base text-gray-500 hover:text-gray-900"
								>
									Webinars
								</a>
							</li>

							<li>
								<a
									href="https://www.dublincore.org/news/"
									class="text-base text-gray-500 hover:text-gray-900"
								>
									News
								</a>
							</li>

							<li>
								<a
									href="https://www.dublincore.org/blog/"
									class="text-base text-gray-500 hover:text-gray-900"
								>
									DCMI Blog
								</a>
							</li>

							<li>
								<a
									href="https://www.dublincore.org/resources/"
									class="text-base text-gray-500 hover:text-gray-900"
								>
									Resources
								</a>
							</li>
						</ul>
					</div>
				</div>
				<div class="md:grid md:grid-cols-2 md:gap-8">
					<div>
						<h3 class="text-base font-medium text-gray-900">Organisation</h3>
						<ul role="list" class="mt-4 space-y-4">
							<li>
								<a
									href="https://www.dublincore.org/about/"
									class="text-base text-gray-500 hover:text-gray-900"
								>
									About DCMI
								</a>
							</li>
							<li>
								<a
									href="https://www.dublincore.org/themes/"
									class="text-base text-gray-500 hover:text-gray-900"
								>
									Themes
								</a>
							</li>
							<li>
								<a
									href="https://www.dublincore.org/themes/community/"
									class="text-base text-gray-500 hover:text-gray-900"
								>
									DCMI Community
								</a>
							</li>

							<li>
								<a
									href="https://www.dublincore.org/members/"
									class="text-base text-gray-500 hover:text-gray-900"
								>
									Members
								</a>
							</li>

							<li>
								<a
									href="https://www.dublincore.org/groups/governing-board/"
									class="text-base text-gray-500 hover:text-gray-900"
								>
									Governance
								</a>
							</li>

							<li>
								<a
									href="https://www.dublincore.org/groups/usage-board/"
									class="text-base text-gray-500 hover:text-gray-900"
								>
									Usage Board
								</a>
							</li>
						</ul>
					</div>
					<div class="mt-12 md:mt-0">
						<h3 class="text-base font-medium text-gray-900">Website</h3>
						<ul role="list" class="mt-4 space-y-4">
							<li>
								<a
									href="https://status.dublincore.org/"
									class="text-base text-gray-500 hover:text-gray-900"
								>
									Service Status
								</a>
							</li>

							<li>
								<a
									href="https://www.dublincore.org/about/privacy/"
									class="text-base text-gray-500 hover:text-gray-900"
								>
									Privacy
								</a>
							</li>

							<li>
								<a
									href="https://www.dublincore.org/about/copyright/"
									class="text-base text-gray-500 hover:text-gray-900"
								>
									Legal
								</a>
							</li>

							<li>
								<a
									href="https://www.dublincore.org/about/contact/"
									class="text-base text-gray-500 hover:text-gray-900"
								>
									Contact
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<div class="pt-8 mt-12 border-t border-gray-200">
			<div class="text-xs text-gray-500 xl:text-center">
				<p>
					Unless indicated otherwise, DCMI documents are licensed under a
					<a
						class="text-blue-800 underline hover:text-blue-900 visited:text-blue-700"
						rel="license"
						href="https://creativecommons.org/licenses/by/4.0/"
					>
						Creative Commons Attribution 4.0 International License
					</a>
					. Please see the
					<a
						class="text-blue-800 underline hover:text-blue-900 visited:text-blue-700"
						href="https://www.dublincore.org/about/copyright/#documentnotice"
					>
						DCMI Document Notice
					</a>
					for further instructions.
				</p>
				<p>
					<a
						class="text-blue-800 underline hover:text-blue-900 visited:text-blue-700"
						href="https://www.dublincore.org/about/copyright/#copyright"
					>
						Copyright
					</a>
					&#169; 1995-2023
					<abbr title="Dublin Core Metadata Initiative">
						<a href="https://www.dublincore.org/">DCMI</a>
					</abbr>
					. All Rights Reserved. DCMI
					<a href="https://www.dublincore.org/about/copyright/#liability">liability</a>
					,
					<a href="https://www.dublincore.org/about/copyright/#trademark">trademark/service mark</a>
					,
					<a href="https://www.dublincore.org/about/copyright/#documentnotice">document use rules</a
					>
					apply. Your interactions with this site are in accordance with our
					<a href="https://www.dublincore.org/about/privacy/">privacy</a>
					statements.
				</p>
				<p>
					The Dublin Core Metadata Initiative (DCMI) is a project of ASIS&amp;T&mdash;a U.S.
					501(c)(3) nonprofit under the U.S. Internal Revenue Code. Contributions to DCMI through
					ASIS&amp;T are tax-deductible to the full extent of the law in the United States.
					<!--      <br /> The <a href="http://purl.org/dcregistry" target="_blank">Dublin Core Metadata Registry</a> is hosted by the <a href="http://www.kc.tsukuba.ac.jp/index_en.html" target="_blank">Research Center for Knowledge Communities</a> at the University Of Tsukuba, Japan. -->
				</p>
				<p class="mt-6 text-xs">
					<!-- Deployed with text -->
				</p>
			</div>
		</div>
	</div>
</footer>
<!-- End:Footer -->
