<script>
	import { writable } from 'svelte/store';
	let menuOpen = writable(false);
</script>

<div class="bg-gray-100" style="min-height: 10px">
	<nav class="bg-white shadow">
		<div class="px-2 mx-auto max-w-7xl sm:px-4 lg:px-8">
			<div class="flex justify-between h-16">
				<div class="flex px-2 lg:px-0">
					<div class="flex items-center flex-shrink-0">
						<a href="/">
							<img
								class="block w-auto h-8 lg:hidden"
								src="/images/logos/dcpapers_v01a.svg"
								alt="DCPapers"
							/>
							<img
								class="hidden w-auto h-8 lg:block"
								src="/images/logos/dcpapers_v01a.svg"
								alt="DCPapers"
							/>
						</a>
					</div>
					<div class="hidden lg:ml-6 lg:flex lg:space-x-8">
						<!-- Current: "border-orange-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" -->

						<a
							href="/issue/dcmi-2023"
							class="inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-500 border-b-2 border-transparent hover:border-gray-300 hover:text-gray-700"
							>Current Issue</a
						>
						<a
							href="/issues"
							class="inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-500 border-b-2 border-transparent hover:border-gray-300 hover:text-gray-700"
							>Past Issues</a
						>
						<!--
						<a
							href="#"
							class="inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-500 border-b-2 border-transparent hover:border-gray-300 hover:text-gray-700"
							>About</a
						>
						-->
					</div>
				</div>
				<div class="items-center justify-center flex-1 hidden px-2 md:flex lg:ml-6 lg:justify-end">
					<div class="w-full max-w-lg lg:max-w-xs">
						<label for="search" class="sr-only">Search</label>
						<div class="relative">
							<div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
								<svg
									class="w-5 h-5 text-gray-400"
									viewBox="0 0 20 20"
									fill="currentColor"
									aria-hidden="true"
								>
									<path
										fill-rule="evenodd"
										d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
										clip-rule="evenodd"
									/>
								</svg>
							</div>
							<form action="/search" method="POST">
								<input
									id="search"
									name="q"
									class="block w-full rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
									placeholder="Search"
									type="search"
								/>
							</form>
						</div>
					</div>
				</div>
				<div class="flex items-center lg:hidden">
					<!-- Mobile menu button -->
					<button
						type="button"
						class="inline-flex items-center justify-center p-2 text-gray-400 rounded-md hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-orange-500"
						aria-controls="mobile-menu"
						aria-expanded="false"
						on:click={() => ($menuOpen = !$menuOpen)}
					>
						<!-- SVGs go here -->
						{#if $menuOpen}
							<!-- SVG for open state -->
							<!--
            Icon when menu is open.

            Menu open: "block", Menu closed: "hidden"
          -->
							<svg
								class="hidden w-6 h-6"
								fill="none"
								viewBox="0 0 24 24"
								stroke-width="1.5"
								stroke="currentColor"
								aria-hidden="true"
							>
								<path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
							</svg>
						{:else}
							<!-- SVG for closed state -->
							<span class="sr-only">Open main menu</span>
							<!--
            Icon when menu is closed.

            Menu open: "hidden", Menu closed: "block"
          -->
							<svg
								class="block w-6 h-6"
								fill="none"
								viewBox="0 0 24 24"
								stroke-width="1.5"
								stroke="currentColor"
								aria-hidden="true"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
								/>
							</svg>
						{/if}
					</button>
				</div>
			</div>
		</div>

		{#if $menuOpen}
			<div class="lg:hidden" id="mobile-menu">
				<div class="pt-2 pb-3 space-y-1">
					<!-- Current: "bg-orange-50 border-orange-500 text-orange-700", Default: "border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800" -->
					<a
						href="/issue/dcmi-2023"
						class="block py-2 pl-3 pr-4 text-base font-medium text-gray-600 border-l-4 border-transparent hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800"
						>Current Issue</a
					>
					<a
						href="/issues"
						class="block py-2 pl-3 pr-4 text-base font-medium text-gray-600 border-l-4 border-transparent hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800"
						>Past Issues</a
					>
					<!--
					<a
						href="#"
						class="block py-2 pl-3 pr-4 text-base font-medium text-gray-600 border-l-4 border-transparent hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800"
						>About</a
					>
					-->
				</div>
			</div>
		{/if}
	</nav>
</div>
